// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-minecraft-index-tsx": () => import("./../../../src/pages/minecraft/index.tsx" /* webpackChunkName: "component---src-pages-minecraft-index-tsx" */),
  "component---src-templates-blog-posts-index-tsx": () => import("./../../../src/templates/blog/posts/index.tsx" /* webpackChunkName: "component---src-templates-blog-posts-index-tsx" */),
  "component---src-templates-minecraft-plugins-index-tsx": () => import("./../../../src/templates/minecraft/plugins/index.tsx" /* webpackChunkName: "component---src-templates-minecraft-plugins-index-tsx" */),
  "component---src-templates-minecraft-plugins-plugin-tsx": () => import("./../../../src/templates/minecraft/plugins/plugin.tsx" /* webpackChunkName: "component---src-templates-minecraft-plugins-plugin-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

